import userManager from "./userManager";

export const signinRedirect = () => {
  localStorage.setItem("redirectUri", "/");
  userManager.signinRedirect();
};

export const signinRedirectCallback = async () => {
  try {
    const response = await userManager.signinRedirectCallback();

    localStorage.setItem("token", response.access_token);
    localStorage.setItem("user", JSON.stringify(response.profile));

    return response;
  } catch (error) {
    localStorage.removeItem("token");
    localStorage.removeItem("user");

    console.error("Error caught in signinRedirectCallback().");
    console.error(error);
  }
};

export const signinSilent = async () => {
  try {
    await userManager.signinSilent();
  } catch (error) {
    console.log("Error caught in signinSilent().");
    console.error(error);
  }
};

export const signinSilentCallback = () => {
  userManager.signinSilentCallback();
};

export const signoutRedirect = () => {
  localStorage.removeItem("token");
  localStorage.removeItem("user");
  
  userManager.clearStaleState();
  userManager.removeUser();

  userManager.signoutRedirect();
};

export const signoutRedirectCallback = () => {
  userManager.removeUser();
  userManager.clearStaleState();

  userManager.signoutRedirectCallback().then(() => {
    localStorage.clear();
    window.location.replace("/");
  });
};
