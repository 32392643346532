import React, { useMemo } from "react";
import { removeToken } from "app/reducer/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { AUTH } from "services";

const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.auth.user);

  const userImage = useMemo(() => {
    if (user.UrlFoto?.includes('http')) {
      return user.UrlFoto;
    }

    const userName = user.Name ?? "bpdlh";
    return `https://ui-avatars.com/api/?name=${userName.replace(" ", "+")}`;
  }, [user]);

  const onLogout = () => {
    dispatch(removeToken());
    AUTH.signoutRedirect();
  };

  return (
    <nav className="navbar bg-white border-b fixed top-0 md:w-[calc(100vw-15rem)] z-[99] px-8">
      <div className="flex-1">
        {/* <a className="btn btn-ghost normal-case text-xl">CMS</a> */}
      </div>
      <div className="flex-none gap-2">
        <div className="dropdown dropdown-end">
          <div className="flex items-center space-x-2">
            {/* <div className="text-sm text-gray-500">incubagong@gmail.com</div> */}
            <div>
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
              </svg>
            </div>
            <div>
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
              </svg>
            </div>
            <div>
              <svg
                aria-hidden="true"
                className="w-6 h-6 text-gray-500 transition duration-75 dark:text-gray-400 group-hover:text-gray-900 dark:group-hover:text-white"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M2 10a8 8 0 018-8v8h8a8 8 0 11-16 0z"></path>
                <path d="M12 2.252A8.014 8.014 0 0117.748 8H12V2.252z"></path>
              </svg>
            </div>
            <label tabIndex="0" className="btn btn-ghost btn-circle avatar">
              <div className="w-10 rounded-full">
                <img src={userImage} alt="profile" />
              </div>
            </label>
          </div>
          <ul
            tabIndex="0"
            className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52"
          >
            <li onClick={() => onLogout()}>
              <a>Logout</a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Header;
