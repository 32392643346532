import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { AUTH } from "services";

const initialState = {
  token: localStorage.getItem("token"),
  user: localStorage.getItem("user"),
};

export const handleLogInAuthentication = createAsyncThunk(
  "auth/signinRedirectCallback",
  async (_, { rejectWithValue }) => {
    try {
      const response = await AUTH.signinRedirectCallback();

      if (!response) {
        throw new Error("user session not found");
      }

      return response;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    removeToken: (state, action) => {
      state.token = action.payload;
    },
    logIn: async (state) => {
      state.isLoading = true;
      state.isSuccess = false;
      state.isError = false;
      state.errorMessage = null;

      AUTH.signinRedirect();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(handleLogInAuthentication.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
        state.isError = false;
        state.errorMessage = null;
      })
      .addCase(handleLogInAuthentication.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.isError = false;
        state.errorMessage = null;

        state.token = action.payload.access_token;
        state.user = action.payload.profile;
      })
      .addCase(handleLogInAuthentication.rejected, (state, action) => {
        state.isLoading = false;
        state.isSuccess = false;
        state.isError = true;
        state.errorMessage = action?.payload;
      });
  },
});

// this is for dispatch
export const { removeToken, logIn } = authSlice.actions;

// this is for configureStore
export default authSlice.reducer;
